import { Link } from "gatsby"
import React from "react"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Image from "../components/Image"
import Layout from "../components/Layout"
import Action from "../components/Resource/Action"
import Section from "../components/Section"
import SEO from "../components/Seo"
import Video from "../components/Video"
import EdGuide from "../docs/Unilever-AmazingMe-ATS-EdGuide-FINAL.pdf"
import bodyConfidentSportPDF from "../docs/Unilever-DSEP-ClassroomActivity-ATSTeacherLedActivity-BodyConfident.pdf"
import celebrandomePDF from "../docs/Unilever-DSEP-ClassroomActivity-ATSTeacherLedActivity-Celebrandome.pdf"
import myHairMyCrownPDF from "../docs/Unilever-DSEP-ClassroomActivity-ATSTeacherLedActivity-MyHairMyCrown.pdf"
import FooterCallout from "../partials/footerCallout"

const MasterClass = () => (
  <Layout>
    <SEO title="The Power of Confidence" />
    {/***************  HERO ***************/}
    <Section className="hero hero__power-of-confidence">
      <Hero
        title={
          <div>
            <p className="header-bar__grade">Grades 6&mdash;12</p>
            <p className={"header-bar--yellow"}>The Power of Confidence</p>
            <p className="header-bar__subhead">
              Empower students to be their best selves.
            </p>
          </div>
        }
        body=""
        image="img-MSHS-hero-circle.png"
      />
    </Section>

    {/***************  QUICKLINKS CONTAINER  ***************/}
    {/* <Section className="quick-link-container">
      <Container>
        <p className="easy-reading-font">
          Every student requires body confidence skills to productively engage
          in their communities, foster healthy relationships, and challenge
          appearance ideals. The animated video series offers you and your
          students relevant lessons focused on body confidence and its role in
          self-esteem.
        </p>
        <div className="quick-links">
          <div className="quick-links__lead quick-links__section">
            Quick Links
          </div>
          <div className="quick-links__links-container quick-links__section">
            <a href="#animated-topic-series">Animated Topic Series</a>
            <a href="#self-esteem">Self-Esteem Resources</a>
            <a href="#classroom-activities">Classroom Activities</a>
          </div>
        </div>
      </Container>
    </Section> */}

    <Section>
      <Container>
        <p style={{ fontSize: "1.1rem", paddingBottom: "1rem" }}>
          Every student requires body confidence skills to productively engage
          in their communities, foster healthy relationships, and challenge
          appearance ideals. The animated video series offers you and your
          students relevant lessons focused on body confidence and its role in
          self-esteem.
        </p>
        <h2>Animated Topic Series</h2>
        <p>
          The Amazing Me: Animated Topic Series encourages young people to
          realize their full potential and improve their body confidence and
          self-esteem.
        </p>
        <Action
          label="Educator Guide"
          type="download"
          size="PDF 1.5MB"
          title=""
          target="_blank"
          rel="noreferrer noopener"
          path={EdGuide}
        >
          EducatorGuide
        </Action>
      </Container>
    </Section>

    {/***************  CONFRONTING COMPARISONS MASTER CLASS ***************/}
    <Section className="power-of-confidence__main-section">
      <Section
        className="power-of-confidence__animated-topic-series"
        id="animated-topic-series"
      >
        <Container>
          <Row>
            <Column span={6}>
              <Video
                style={{
                  aspectRatio: "16/9",
                  height: "auto",
                }}
                guidSrc={"95be344c-3626-4c3c-a977-55e748313ac6"}
              />
            </Column>
            <Column span={6}>
              <h2>Celebrándome</h2>
              <p>
                Explore the rich potential of Celebrándome in understanding how
                colorism influences self-identity and the importance of
                embracing and celebrating the diversity of skin colors within
                one’s own cultural context.
              </p>
              <Action
                label="Watch Video"
                type="video"
                guidSrc={"95be344c-3626-4c3c-a977-55e748313ac6"}
              >
                Watch Video
              </Action>
              <Action
                label="Classroom Activity"
                type="download"
                size="PDF 3.1MB"
                title=""
                target="_blank"
                rel="noreferrer noopener"
                path={celebrandomePDF}
              >
                Classroom Activity
              </Action>
            </Column>
          </Row>
          <Row style={{ paddingTop: "2rem" }}>
            <Column span={6}>
              <Video
                style={{
                  aspectRatio: "16/9",
                  height: "auto",
                }}
                guidSrc={"86c29bca-1538-48ac-a3ac-ae96dd5631f8"}
              />
            </Column>
            <Column span={6}>
              <h2>My Hair, My Crown</h2>
              <p>
                Watch to discover how hair as a creative form of expression is
                deeply tied to identity within BIPOC cultures. Explore the
                influence of social media on shaping perceptions of
                self-identity and the impact of digital interactions.
              </p>
              <Action
                label="Watch Video"
                type="video"
                guidSrc={"86c29bca-1538-48ac-a3ac-ae96dd5631f8"}
              >
                Watch Video
              </Action>
              <Action
                label="Classroom Activity"
                type="download"
                size="PDF 4MB"
                title=""
                target="_blank"
                rel="noreferrer noopener"
                path={myHairMyCrownPDF}
              >
                Classroom Activity
              </Action>
            </Column>
          </Row>
          <Row style={{ paddingTop: "2rem" }}>
            <Column span={6}>
              <Video
                style={{
                  aspectRatio: "16/9",
                  height: "auto",
                }}
                guidSrc={"12ac75cd-5c01-4256-86ad-fbce83b4b421"}
              />
            </Column>
            <Column span={6}>
              <h2>Body Confident Sport</h2>
              <p>
                This video dives into what it takes to feel confident in our
                bodies while participating in sports and why discovering what
                our bodies can do is important to building a positive body
                image.
              </p>
              <Action
                label="Watch Video"
                type="video"
                guidSrc={"12ac75cd-5c01-4256-86ad-fbce83b4b421"}
              >
                Watch Video
              </Action>
              <Action
                label="Classroom Activity"
                type="download"
                size="PDF 3.3MB"
                title=""
                target="_blank"
                rel="noreferrer noopener"
                path={bodyConfidentSportPDF}
              >
                Classroom Activity
              </Action>
            </Column>
          </Row>
        </Container>
      </Section>
      {/***************  SELF-ESTEEM RESOURCES ***************/}
      {/* <Section className="power-of-confidence__self-esteem" id="self-esteem">
        <Container>
          <h3 className="header-bar--yellow">
            Self-Esteem Resources (from DSEP)
          </h3>
          <Row>
            {selfEsteemResources.map((item, i) => (
              <Column span={4} key={i}>
                <Resource label="RESOURCE KIT" {...item} inGrid />
              </Column>
            ))}
          </Row>
        </Container>
      </Section> */}
      {/***************  SELF-ESTEEM RESOURCES ***************/}
      {/* <Section
        className="power-of-confidence__classroom-activities"
        id="classroom-activities"
      >
        <Container>
          <h3 className="header-bar--yellow">
            Supporting Classroom Activities
          </h3>
          <Row>
            {supportingClassroomResources.map((item, i) => (
              <Column span={4} key={i}>
                <Resource label="CLASSROOM ACTIVITY" {...item} inGrid />
              </Column>
            ))}
          </Row>
        </Container>
      </Section> */}
    </Section>

    {/***************  CTA ***************/}
    {/*  <Section className="master-class-cta">
      <Container>
        <Link to="/classroom-resources" className="cta">
          Classroom resources for 6th–12th grade students.
          <img
            src={ctaArrow}
            alt={"Go to Classroom Resources"}
            className={"cta__arrow"}
          />
        </Link>
      </Container>
    </Section> */}

    {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
    <Section style={{ marginTop: "2rem" }} className={"squiggle"}>
      <Container>
        <h3>Explore Additional Resources</h3>
        <Row>
          <Column span={5} className={"is-offset-1 resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-master-class.png"}
            />
            <h4 className={"bold-text"}>Master Class Series</h4>
            <p>
              The Master Class videos provide educators with skills to encourage
              kindness and open discourse around the topics of body
              dissatisfaction and bullying. 
            </p>
            <Link to={"/master-class"}>Learn More</Link>
          </Column>
          <Column span={5} className={"resource"}>
            <Image
              className={"resource-image"}
              filename={"img-promo-family.png"}
            />
            <h4 className={"bold-text"}>Family Resources</h4>
            <p>
              Encourage body positivity and self-esteem at home with these
              important family resources.
            </p>
            <Link to={"/family-resources"}>Learn More</Link>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className="midgray-background body-confidence-fact--vft">
      <Container>
        <Row>
          <Column span={7}>
            <p>Body Confidence </p>
            <p>
              When kids are confident and secure about who they are, they’re
              more likely to have a growth mindset. That means they can motivate
              themselves to take on new challenges and to cope with and learn
              from mistakes.
            </p>

            <p>
              &mdash;<em>Understood.org</em>
            </p>
          </Column>
          <Column
            span={4}
            className={"is-offset-1 body-confidence-fact__container"}
          >
            <div className={"body-confidence-fact__container__image"}>
              <Image filename={"img-subfooter-6.png"} className={""} />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  FOOTER CALLOUT ***************/}
    <FooterCallout />
  </Layout>
)

export default MasterClass
